.invoice-preview-component {
  .invoice-footer {
    font-face: "Open Sans";
    position: absolute;
    margin-left: calc(10.5cm - 200px);
    top: calc(29.7cm - 100px);
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  .nav-online {
    font-size: 12px;
    margin-top: 10px;
  }

  .happy {
    align-items: center;
    display: flex;
    justify-content: center;
    width: 400px;
  }

  .watermark {
    align-items: center;
    display: flex;
    gap: 0.25rem;
    justify-content: center;
  }

  .watermark span {
    color: #f1b44c !important;
    font-weight: 600;
    margin-right: 0.25rem !important;
    text-decoration: none !important;
  }

  page {
    background: white;
  }

  .copyright p {
    color: var(--textLight) !important;
    font-size: clamp(0.65rem, 1.2vw, 0.8rem) !important;
    line-height: 25px;
    text-align: center;
  }

  page[size="A4"] {
    width: 21cm;
    height: 29.7cm;
  }

  page[size="A4"][layout="landscape"] {
    width: 29.7cm;
    height: 21cm;
  }

  @media print {
    body,
    page {
      background: white;
      margin: 0;
      box-shadow: 0;
    }
  }

  @import url(https://fonts.googleapis.com/css?family=Open+Sans:400,700);

  div,
  p,
  a,
  li,
  td {
    -webkit-text-size-adjust: none;
  }

  .ReadMsgBody {
    width: 100%;
    background-color: #ffffff;
  }

  .ExternalClass {
    width: 100%;
    background-color: #ffffff;
  }

  body {
    width: 100%;
    height: 100%;
    background-color: #e1e1e1;
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
  }

  p {
    padding: 0 !important;
    margin-top: 0 !important;
    margin-right: 0 !important;
    margin-bottom: 0 !important;
    margin-left: 0 !important;
  }

  .visibleMobile {
    display: none;
  }

  .hiddenMobile {
    display: block;
  }

  @media only screen and (max-width: 600px) {
    body {
      width: auto !important;
    }
    table[class="fullTable"] {
      width: 100% !important;
      clear: both;
    }
    table[class="fullPadding"] {
      width: 100% !important;
      clear: both;
    }
    table[class="col"] {
      width: 45% !important;
    }
    .erase {
      display: none;
    }
  }

  @media only screen and (max-width: 420px) {
    table[class="fullTable"] {
      width: 100% !important;
      clear: both;
    }
    table[class="fullPadding"] {
      width: 100% !important;
      clear: both;
    }
    table[class="col"] {
      width: 100% !important;
      clear: both;
    }
    table[class="col"] td {
      text-align: left !important;
    }
    .erase {
      display: none;
      font-size: 0;
      max-height: 0;
      line-height: 0;
      padding: 0;
    }
    .visibleMobile {
      display: block !important;
    }
    .hiddenMobile {
      display: none !important;
    }
  }
}