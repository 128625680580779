/**************************************
    Default Styles
***************************************/
@import url("https://cdn.jsdelivr.net/npm/bootstrap@4.4.1/dist/css/bootstrap.min.css");
@import url("../css/eduvibe-font.css");
@import url("../css/remixicon.css");
@import url("../css/animation.css");
@import url("../css/slick.css");
@import url("../css/slicktheme.css");

.swal2-container {
  div {
    transform: scale(1.5) !important;
  }

  button.swal2-styled {
    transform: scale(0.8) !important;
  }
}

.banner-style-1 .shape-dot-wrapper .shape.shape-2 {
  top: 112px !important;
  left: 288px !important;
}

.edu-header {
  padding: 0 !important;
  padding-right: 30px !important;
}

@import "default/variables";
@import "default/mixins";
@import "default/reset";
@import "default/typography";
@import "default/extend";
@import "default/animations";
@import "default/shortcode";
@import "default/spacing";
@import "default/common";
@import "default/forms";

/**************************************
    Header Styles
***************************************/
@import "header/header";
@import "header/nav";
@import "header/header-quote";
@import "header/mobilemenu";
@import "header/search-popup";

/**************************************
    Elemnts Styles
***************************************/
@import "elements/button";
@import "elements/brands";
@import "elements/section-title";
@import "elements/section-animation";
@import "elements/card";
@import "elements/meta";
@import "elements/badge";
@import "elements/pagination";
@import "elements/event";
@import "elements/team";
@import "elements/service";
@import "elements/single-elements";
@import "elements/coursesidebar";
@import "elements/testimonial";
@import "elements/feature";
@import "elements/about";
@import "elements/newsletter";
@import "elements/social-share";
@import "elements/backtotop";
@import "elements/workshop";
@import "elements/video-popup";
@import "elements/chooseus";
@import "elements/calltoaction";
@import "elements/slickslider";
@import "elements/winner";
@import "elements/counterup";
@import "elements/countdown";
@import "elements/breadcrumb";
@import "elements/masonaryitem";
@import "elements/accordion";
@import "elements/privacy-policy";
@import "elements/404";
@import "elements/coming-soon";
@import "elements/gallery";
@import "elements/pricingtable";
@import "elements/profile-details";
@import "elements/progressbar";
@import "elements/contactus";
@import "elements/shop";
@import "elements/brand";
@import "elements/checkout";
@import "elements/overall";

/**************************************
    Blog Styles
***************************************/
@import "blog/blog-grid";
@import "blog/sidebar";
@import "blog/blog-details";
@import "blog/comments";

/**************************************
    Template Styles
***************************************/
@import "template/banner";
@import "template/event-details";
@import "template/product-details";
@import "template/cart";
@import "template/course-details";
@import "template/preview";

/**************************************
    Footer Styles
***************************************/
@import "footer/footer";
@import "footer/copyright";

html {
  scroll-behavior: smooth;
}

.modal-content {
  border-radius: 6px !important;
  border: none !important;
}

.Mui-checked + .MuiSwitch-track {
  background: rgba(236, 206, 122, 0.68) !important;
}

.MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
  opacity: 1 !important;
}

.MuiSwitch-thumb {
  background: #393939 !important;
}

.Mui-checked {
  .MuiSwitch-thumb {
    background: #f1b44c !important;
  }

  .MuiSvgIcon-root {
    fill: #f1b44c !important;
  }
}

.MuiSlider-root {
  color: #f1b44c !important;
}

[data-quantity] {
  position: relative;
  width: 100%;
  max-width: 11rem;
  padding: 0;
  margin: 0;
  border: 0;
}

[data-quantity] legend {
  display: none;
}

[data-quantity] input {
  font-size: 18px;
  height: 4rem;
  padding: 0 4rem;
  border-radius: 2rem;
  border: 0;
  background: #fff;
  color: #222;
  box-shadow: 0 10px 65px -10px rgba(0, 0, 0, 0.25);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  text-align: center;
  width: fit-content;
  box-sizing: border-box;
  font-weight: lighter;
}

[data-quantity] input:focus {
  outline: none;
  box-shadow: 0 5px 55px -10px rgba(0, 0, 0, 0.2), 0 0 4px #f1b44c; /* Allows border radius on focus */
}

[data-quantity] input[type="number"]::-webkit-inner-spin-button,
[data-quantity] input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

[data-quantity] input[type="number"] {
  -moz-appearance: textfield;
}

[data-quantity] button {
  position: absolute;
  width: 2.8rem;
  height: 2.8rem;
  top: 0.6rem;
  display: block;
  padding: 0;
  margin: 0;
  border: 0;
  background: #fff
    url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="100" height="50"><path d="M17 25h16M67 25h16M75 17v16" fill="none" stroke="rgb(59,69,66)" stroke-width="1.5px" stroke-linecap="round" stroke-linejoin="round" /></svg>')
    no-repeat 0 0;
  background-size: 5.6rem 2.8rem;
  overflow: hidden;
  white-space: nowrap;
  text-indent: 100%;
  border-radius: 1.4rem;
  cursor: pointer;
  transition: opacity 0.15s;
  opacity: 0.5;
}

[data-quantity] button:active {
  background-position-y: 1px;
  box-shadow: inset 0 2px 12px -4px #c5d1d9;
}

[data-quantity] button:focus {
  outline: none;
}

[data-quantity] button:hover {
  opacity: 1;
}

[data-quantity] button.sub {
  left: 0.6rem;
}

[data-quantity] button.add {
  right: 0.6rem;
  background-position-x: -2.8rem;
}

.order-summary-modal {
  .modal-dialog {
    width: 80vw;
    max-width: 80vw;
  }
}

.MuiTable-root {
  margin: 0 !important;
  background: #f1f1f18a !important;
  border-radius: 2px !important;

  * {
    font-size: 1.4rem !important;
  }

  td,
  th {
    width: calc(100% / 6) !important;
  }
}

.stripe-form {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 2.5rem 1.25rem !important;
  gap: 1rem;
  z-index: 100;
  @media only screen and (max-width: 768px) {
    padding: 1rem !important;
  }

  .field {
    width: 100% !important;
    flex: 1 !important;
  }

  .top {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .card-element {
    width: 100% !important;

    .__PrivateStripeElement {
      width: 100% !important;

      iframe {
        width: 100% !important;
      }
    }
  }

  .input,
  input,
  #card-element,
  .field {
    border: none !important;
    outline: none !important;
    -webkit-appearance: none;
    border-radius: 4px !important;
    background: transparent !important;
    box-shadow: 0 2px 5px -1px rgba(50, 50, 93, 0.25),
      0 1px 3px -1px rgba(0, 0, 0, 0.3) !important;
    height: 2.5rem !important;
    padding: 4px 10px !important;

    &::placeholder {
      color: #435162 !important;
    }
  }

  .field {
    padding: 11px 10px !important;
  }

  .group {
    width: 100%;
    border-radius: 4px;
    padding: 0.5rem !important;
    display: flex;
    gap: 1rem;
    flex-direction: column;
    min-height: 4rem;

    @media only screen and (max-width: 768px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  .outcome {
    float: left;
    width: 100%;
    padding-top: 8px;
    min-height: 24px;
    text-align: center;
  }

  .success,
  .error {
    display: none;
    font-size: 13px;
  }

  .success.visible,
  .error.visible {
    display: inline;
  }

  .error {
    color: #e4584c;
  }

  .success {
    color: #666ee8;
  }

  .success .token {
    font-weight: 500;
    font-size: 13px;
  }
}

.aszf-container,
.adatkezeles-container {
  padding: 4rem 2rem !important;

  ul {
    li {
      list-style: none !important;
    }
  }

  ul.styled {
    li {
      list-style: disc !important;
    }
  }
}

a {
  cursor: pointer;
}

.partner-buttons {
  display: flex;
  align-items: center;
  gap: 0 !important;

  @media only screen and (max-width: 1200px) {
    display: none;
  }
}

.contact-modal {
  .form-group {
    margin-bottom: 12.5px !important;
  }
}

.modal-backdrop.show {
  opacity: 0.25 !important;
}

.logo {
  h4 {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    small {
      font-size: 1.5rem;
      font-weight: 550;
      color: #f1b44c;
    }
  }
}

@media (max-width: 720px) {
  .edu-header {
    padding: 0 !important;

    .logo {
      width: 84vw !important;
    }

    .hamberger {
      padding-right: 15px;
    }
  }
}

@media (min-width: 720px) {
  .edu-header {
    padding: 0 !important;

    .h4 {
      width: 100% !important;

      small {
        text-wrap: none;
      }
    }

    .hamberger {
      padding-right: 15px;
    }
  }
}

.footer-content {
  .column {
    width: 50% !important;
  }
}

.aszf-container {
  * {
    width: 100% !important;
  }

  .normal0 {
    * {
      width: fit-content !important;
    }
  }
}

.submenu {
  padding: 0 !important;

  li {
    padding: 0 !important;

    a {
      padding: 1.5rem 1rem !important;
    }
  }
}

.popup-mobile-menu {
  .inner {
    z-index: 80 !important;
  }
}

.viva-main {
  margin: 0 auto !important;

  img {
    filter: invert(1) brightness(300%);
    max-width: 28rem;
  }
}

.viva {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: center;

  img {
    height: 3.5rem;
    background: #fff;
    padding: 0.45rem 0.65rem !important;
    border-radius: 12px;
  }
}

.aszf-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;

  * {
    font-family: "Urbanist", sans-serif !important;
    color: #666 !important;
    background: transparent !important;
  }

  p {
    line-height: 1.6;
    font-size: 16px;
    color: #666;
  }

  a {
    color: #337ab7;
    text-decoration: none;

    &:hover {
      color: #23527c;
    }
  }

  .title {
    font-size: 24px;
    color: #333;
    margin-bottom: 20px;
  }

  .description {
    margin-bottom: 40px;
  }
}

.module-prices-table {
  max-width: 850px;
  border-radius: 12.5px;
  margin: 0 auto;
  background: #fff;

  th {
    white-space: nowrap;
    padding: 7px 10px;
  }

  .head,
  .head th,
  .head th tr,
  .head th tr th,
  .head th tr td {
    min-height: 5rem;
    height: 5rem;
  }

  .head {
    th {
      font-size: 1.65rem !important;
    }
  }

  td,
  th {
    width: calc(100% / 5);
  }

  svg {
    font-size: 2.35rem !important;
  }

  svg[data-testid="CheckIcon"] {
    color: green;
  }

  svg[data-testid="CloseIcon"] {
    color: red;
  }
}

.table-wrapper {
  @media (max-width: 800px) {
    max-width: 90vw !important;
    overflow-x: auto;
  }
}

span.title {
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    color: var(--color-primary);
  }
}

.head.sticky {
  background: #fff !important;
  position: sticky !important;
  top: 80px !important;
  z-index: 99999999 !important;
  transition: all 0.2s !important;

  th {
    border-bottom: 1px solid var(--color-border);
  }
}

body,
html {
  overflow-x: hidden !important;
}

.shape-dot-wrapper {
  position: relative !important;
  overflow: hidden !important;
}

#unique-restaurant {
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px !important;
  border: none !important;
}

@media (max-width: 920px) {
  .extras-list > div {
    flex-basis: 100% !important;
  }
}

.happy-services {
  li {
    list-style: circle !important;
  }
  .d-flex {
    @media screen and (max-width: 940px) {
      flex-direction: column !important;
      gap: 2rem !important;
    }
  }
}

.device-row {
  width: 100% !important;
  justify-content: space-between !important;
}

.price-pc {
  @media only screen and (max-width: 1000px) {
    display: none !important;
  }
}

.transform-component-module_content__FBWxo {
  height: auto !important;
  overflow: visible !important;
}

.transform-component-module_wrapper__SPB86 {
  height: auto !important;
  overflow: visible !important;


}

.price-mobile {
  display: none !important;
  @media only screen and (max-width: 1000px) {
    display: flex !important;
    padding: 0 !important;
    h6 {
      white-space: inherit !important;
      padding-right: 1rem !important;
    }
    .feature-content {
      align-items: flex-start !important;
    }
    * {
      font-weight: 450 !important;
    }
  }
}
.device-row {
  @media only screen and (max-width: 500px) {
    h6 {
      font-size: 1.55rem !important;
    }
    img {
      width: 60px !important;
      height: 60px !important;
    }
  }
  .feature-content {
    justify-content: center !important;
  }
}
@media only screen and (max-width: 1000px) {
  .device-row {
    .feature-content {
      align-items: flex-start !important;
    }
  }
}
